import React from "react";
import { Link } from "gatsby";
import InformationIcon from "../../images/svg/icons/information-icon.svg";

interface LeaderBoardBannerAdProps {
  index?: string;
}

/**
 * Leaderboard Banner Advertisement for homepage
 * @param {string} [index] - index of Leaderboard Banner if more are required on page
 *
 */

const LeaderBoardBannerAd = ({
  index,
}: LeaderBoardBannerAdProps): React.JSX.Element => {
  return (
    <div className="adunit adunit--leaderboard-banner">
      <div className="adunit__container">
        <div className="code-block code-block--banner">
          <div className="divider">
            <span>Advertisement</span>
          </div>
          <div
            id={index ? `LeaderBelowTitle_${index}` : `LeaderBelowTitle`}
            className="ad-container"
          />
        </div>
        <div className="adunit__cta">
          <div className="adunit__cta-text">
            <InformationIcon />
            Looking to advertise on this website?
          </div>
          <div className="adunit__cta-link">
            <Link to="/en/contact">Find out more</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardBannerAd;
